import { Box } from "@chakra-ui/react";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import useSWR from "swr";
import Error from "../../components/Error";
import useAuth from "../../hooks/useAuth";
import useApiFetcher from "../../hooks/useApiFetcher";
import { apiGetErrorStatus } from "../../helpers/api";

/**
 * @typedef {object} ConfigurationContextValue
 * @property {import("../../types/Configuration").Configuration} configuration
 * @property {() => void} mutate
 */

/** @type {ConfigurationContextValue} */
const DefaultValue = {
  configuration: {
    environments: [],
  },
  mutate: () => {},
};

/**
 * Détermine si on doit afficher children ou non
 * @param {any} error
 * @returns {boolean}
 */
function shouldRenderChildren(error) {
  const maintenanceRequest = 503 === apiGetErrorStatus({ error });

  if (error) {
    return maintenanceRequest;
  }

  return true;
}

/** @type {React.Context<ConfigurationContextValue>} */
export const ConfigurationContext = createContext(DefaultValue);

/**
 * @typedef {Object} Props
 * @property {import("../../types/Configuration").Configuration} [initialConfiguration]
 * @property {string | null} [url]
 * @property {React.ReactNode} children
 *
 * @param {Props} props
 */
export const ConfigurationProvider = ({
  children,
  initialConfiguration: _initialConfiguration,
  url = null,
}) => {
  const apiFetcher = useApiFetcher();

  const initialConfiguration = useRef(_initialConfiguration);

  const { data, mutate, error } = useSWR(url, apiFetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: true,
    refreshInterval: 0,
    dedupingInterval: 0,
    fallbackData: {
      data: initialConfiguration.current,
    },
  });

  /** @type {import("../../types/Configuration").Configuration} */
  const configuration = data?.data; // forcing type as the configuration cannot be undefined

  const value = useMemo(() => {
    return {
      configuration: configuration ?? {},
      mutate,
    };
  }, [configuration, mutate]);

  const { user } = useAuth();

  const [remanentUser, setRemanentUser] = useState(user);

  useEffect(() => {
    if (user?.id !== remanentUser?.id) {
      setRemanentUser(user);
      mutate();
    }
  }, [mutate, remanentUser?.id, user]);

  return (
    <ConfigurationContext.Provider value={value}>
      {shouldRenderChildren(error) ? (
        children
      ) : (
        <Box p="1rem">
          <Error.Global error={error} />
        </Box>
      )}
    </ConfigurationContext.Provider>
  );
};
