// deps
import { PHASE_PRODUCTION_BUILD } from "next/constants";

// hooks
import { serverApiFetcher } from "../../../hooks/useApiFetcher";

// libraries
import generateApiUrl from "../../../libraries/utils/generateApiUrl";

/**
 * @typedef {Promise<{user?: import("../../../types/User").User, error?: any}>} NextGetUserReturn
 */

/**
 * Récupère les informations sur l’utilisateur courant.
 * @param {object} param0
 * @param {object} param0.cookies
 * @param {import("@splitfire-agency/raiden-library/dist/libraries/Uri").default} param0.baseUri
 * @param {string} [param0.locale]
 * @param {object} param0.req
 * @return {NextGetUserReturn}
 */
export default async function nextGetUser({ cookies, baseUri, locale, req }) {
  let error = undefined;

  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      user: undefined,
      error: undefined,
    };
  }

  const userResponse = await serverApiFetcher(
    generateApiUrl({
      id: "@api.auth.me",
      query: {
        fields: [
          "user.avatar",
          "user.customer.validations",
          "user.customer.global_subscriptions",
          "customer.default_address",
          "groups",
        ],
      },
    }),
    undefined,
    {
      cookies,
      origin: baseUri.toString(),
      locale,
      req,
    },
  ).catch(function (tempError) {
    console.log("An error occured while fetching the user", tempError);
    error = tempError;
  });

  return {
    user: userResponse?.data,
    error,
  };
}
