export const COOKIES_NAME_VALUE_PAGINATION_PER_PAGE = "pagination-per-page";
export const COOKIES_NAME_VALUE_NEXT_LOCALE = "NEXT_LOCALE";
export const COOKIES_NAME_VALUE_CHAKRA_UI_COLOR_MODE = "chakra-ui-color-mode";
export const COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS = "default-environments";
export const COOKIES_NAME_VALUE_ACCEPT_COOKIES = "accept-cookies";
export const COOKIES_NAME_VALUE_BYPASS_MAINTENANCE = "bypass-maintenance";

export const COOKIES_NAMES = {
  [COOKIES_NAME_VALUE_PAGINATION_PER_PAGE]: {
    id: COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
  },
  [COOKIES_NAME_VALUE_NEXT_LOCALE]: {
    id: COOKIES_NAME_VALUE_NEXT_LOCALE,
  },
  [COOKIES_NAME_VALUE_CHAKRA_UI_COLOR_MODE]: {
    id: COOKIES_NAME_VALUE_CHAKRA_UI_COLOR_MODE,
  },
  [COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS]: {
    id: COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS,
  },
  [COOKIES_NAME_VALUE_ACCEPT_COOKIES]: {
    id: COOKIES_NAME_VALUE_ACCEPT_COOKIES,
  },
  [COOKIES_NAME_VALUE_BYPASS_MAINTENANCE]: {
    id: COOKIES_NAME_VALUE_BYPASS_MAINTENANCE,
  },
};

export const COOKIES_NAME_LIST = Object.values(COOKIES_NAMES);
