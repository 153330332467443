/* istanbul ignore file */

// deps
import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import cookie from "cookie";

// constants
import { COOKIES_NAME_VALUE_NEXT_LOCALE } from "../constants/cookies";
import browser from "../constants/browser";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

/**
 * Retourne différentes composants pour récupérer la liste des langues disponibles,
 *   la langue courante ainsi qu’une méthode pour modifier la langue courante.
 */
export default function useLocale() {
  const router = useRouter();

  /**
   * Change la langue utilisée.
   */
  const setLocale = useCallback(
    /**
     * @param {string} locale
     * @param {object} options
     * @param {boolean} [options.saveInCookie]
     */
    function (locale, options = {}) {
      const { saveInCookie = false } = options;

      if (browser && saveInCookie) {
        document.cookie = cookie.serialize(
          COOKIES_NAME_VALUE_NEXT_LOCALE,
          locale,
          {
            path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
            secure: true,
          },
        );
      }

      router.replace(
        { pathname: router.pathname, query: router.query },
        undefined,
        {
          locale,
          shallow: true,
        },
      );
    },
    [router],
  );

  const patchedLocales = useMemo(
    () =>
      router.locales?.filter(
        (locale) => locale !== process.env.NEXT_PUBLIC_FAKE_LOCALE,
      ),
    [router.locales],
  );

  return {
    setLocale,
    locale: router.locale,
    locales: patchedLocales,
    defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
  };
}
