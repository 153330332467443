// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

export const adminBaseUri = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_ADMIN_SCHEME,
  null,
  process.env.NEXT_PUBLIC_ADMIN_HOST,
  null,
  null,
  null,
  null,
);

export const adminBaseUriWithBasePath = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_ADMIN_SCHEME,
  null,
  process.env.NEXT_PUBLIC_ADMIN_HOST,
  null,
  process.env.NEXT_PUBLIC_ADMIN_BASE_PATH,
  null,
  null,
);

/**
 * Build a router.
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @return {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default})}
 */
function buildRouter(baseUri) {
  const adminRouter = new Router();

  adminRouter.group(baseUri, (adminRouter) => {
    // assets (previewer)
    adminRouter
      .get("/public/<filePath>")
      .setId("@admin.assets")
      .setConstraint("filePath", /.*/);

    // internal assets (nextjs)
    adminRouter
      .get(`/<filePath>`)
      .setId("@admin.internal-assets")
      .setConstraint("filePath", /.*/);

    adminRouter.get("").setId("@admin.home");

    // Login
    adminRouter.get("/login").setId("@admin.login");

    // Password recovery
    adminRouter.get("/password-recovery").setId("@admin.password-recovery");

    // Dashboard
    adminRouter.get("/dashboard").setId("@admin.dashboard");

    // Health
    adminRouter.group("/health/status", (adminRouter) => {
      adminRouter.get("").setId("@admin.healthStatus.view");
    });

    // Internal
    adminRouter.group("/internal", (adminRouter) => {
      adminRouter.group("/horizon", (adminRouter) => {
        adminRouter.get("").setId("@admin.internalHorizon.view");
      });
      adminRouter.group("/telescope", (adminRouter) => {
        adminRouter.get("").setId("@admin.internalTelescope.view");
      });
    });

    // Environments
    adminRouter.group("/environments", (adminRouter) => {
      adminRouter.get("").setId("@admin.environments.search");

      adminRouter.get("/create").setId("@admin.environments.create");

      adminRouter.group("/<environmentId>", (adminRouter) => {
        adminRouter.setConstraint("environmentId", /\d+/);

        adminRouter.get("/show").setId("@admin.environments.view");

        adminRouter.group("/edit", (adminRouter) => {
          adminRouter.get("").setId("@admin.environments.update");
          adminRouter
            .get("/records_accounts")
            .setId("@admin.environments.editRecordsAccounts");
        });
      });
    });

    adminRouter.group("/users", (adminRouter) => {
      // Users/Admins
      adminRouter.group("/admins", (adminRouter) => {
        adminRouter.get("").setId("@admin.usersAdmins.search");

        adminRouter.get("/create").setId("@admin.usersAdmins.create");

        adminRouter.group("/me", (adminRouter) => {
          adminRouter.get("/").setId("@admin.usersAdmins.me");
          adminRouter.get("/2fa").setId("@admin.usersAdmins.me.2fa");
        });

        adminRouter.group("/<usersAdminId>", (adminRouter) => {
          adminRouter.setConstraint("usersAdminId", /\d+/);

          adminRouter.get("/show").setId("@admin.usersAdmins.view");

          adminRouter.get("/edit").setId("@admin.usersAdmins.update");
        });
      });

      // Users/Groups
      adminRouter.group("/groups", (adminRouter) => {
        adminRouter.get("").setId("@admin.usersGroups.search");

        adminRouter.get("/create").setId("@admin.usersGroups.create");

        adminRouter.group("/<usersGroupId>", (adminRouter) => {
          adminRouter.setConstraint("usersGroupId", /\d+/);

          adminRouter.get("/show").setId("@admin.usersGroups.view");

          adminRouter.get("/edit").setId("@admin.usersGroups.update");
        });
      });
    });

    adminRouter.group("/posts", (adminRouter) => {
      adminRouter.group("/<postType>", (adminRouter) => {
        adminRouter.setConstraint("postType", /[a-z]+/);

        adminRouter.get("/create").setId("@admin.posts.create");

        adminRouter.get("").setId("@admin.posts.search");

        adminRouter.group("/categories", (adminRouter) => {
          adminRouter.get("").setId("@admin.postsCategories.search");

          adminRouter.get("/create").setId("@admin.postsCategories.create");

          adminRouter
            .get("/tree-edit")
            .setId("@admin.postsCategories.treeUpdate");

          adminRouter.group("/<postsCategoryId>", (adminRouter) => {
            adminRouter.get("/show").setId("@admin.postsCategories.view");

            adminRouter.get("/edit").setId("@admin.postsCategories.update");
          });
        });

        adminRouter.group("/<postId>", (adminRouter) => {
          adminRouter.setConstraint("postId", /\d+/);

          adminRouter.get("/show").setId("@admin.posts.view");

          adminRouter
            .get("/edit-revision")
            .setId("@admin.posts.updateRevision");
        });
      });
    });

    // Countries
    adminRouter.group("/countries", (adminRouter) => {
      adminRouter.get("").setId("@admin.countries.search");
      adminRouter.get("/create").setId("@admin.countries.create");
      adminRouter.group("/<countryId>", (adminRouter) => {
        adminRouter.setConstraint("countryId", /\d+/);
        adminRouter.get("/edit").setId("@admin.countries.update");
      });
    });

    // Menus
    adminRouter.group("/menus", (adminRouter) => {
      adminRouter.get("").setId("@admin.menus.search");
      adminRouter.get("/create").setId("@admin.menus.create");

      adminRouter.group("/<menuId>", (adminRouter) => {
        adminRouter.get("/view").setId("@admin.menus.view");
        adminRouter.get("/update").setId("@admin.menus.update");
      });
    });

    // Invoices
    adminRouter.group("/invoices", (adminRouter) => {
      adminRouter.get("").setId("@admin.invoices.search");
    });

    // Account records
    adminRouter.group("/account-records", (adminRouter) => {
      adminRouter.get("").setId("@admin.accountRecords.search");
    });

    // Customers
    adminRouter.group("/customers", (adminRouter) => {
      adminRouter.get("").setId("@admin.customers.search");
      adminRouter.get("/create").setId("@admin.customers.create");

      adminRouter.group("/<customerId>", (adminRouter) => {
        adminRouter.setConstraint("customerId", /\d+/);
        adminRouter.get("/show").setId("@admin.customers.view");
        adminRouter.get("/update").setId("@admin.customers.update");
      });

      // Customers/Contacts
      adminRouter.group("/contacts", (adminRouter) => {
        adminRouter.get("").setId("@admin.customers.contacts.search");
        adminRouter.get("/create").setId("@admin.customers.contacts.create");

        adminRouter.group("/<customerContactId>", (adminRouter) => {
          adminRouter.setConstraint("customerContactId", /\d+/);
          adminRouter.get("/show").setId("@admin.customers.contacts.view");
          adminRouter.get("/update").setId("@admin.customers.contacts.update");
        });

        // Customers/Contacts/Categories
        adminRouter.group("/categories", (adminRouter) => {
          adminRouter
            .get("")
            .setId("@admin.customers.contacts.categories.search");
          adminRouter
            .get("/create")
            .setId("@admin.customers.contacts.categories.create");

          adminRouter.group("/<customerContactCategoryId>", (adminRouter) => {
            adminRouter.setConstraint("customerContactCategoryId", /\d+/);
            adminRouter
              .get("/show")
              .setId("@admin.customers.contacts.categories.view");
            adminRouter
              .get("/update")
              .setId("@admin.customers.contacts.categories.update");
          });

          // Customers/Contacts/Categories/Custom-attributes
          adminRouter.group("/custom-attributes", (adminRouter) => {
            adminRouter
              .get("")
              .setId(
                "@admin.customers.contacts.categories.custom_attributes.search",
              );
            adminRouter
              .get("/create")
              .setId(
                "@admin.customers.contacts.categories.custom_attributes.create",
              );

            adminRouter.group(
              "/<customerContactCategoryCustomAttributeId>",
              (adminRouter) => {
                adminRouter.setConstraint(
                  "customerContactCategoryCustomAttributeId",
                  /\d+/,
                );
                adminRouter
                  .get("/show")
                  .setId(
                    "@admin.customers.contacts.categories.custom_attributes.view",
                  );
                adminRouter
                  .get("/update")
                  .setId(
                    "@admin.customers.contacts.categories.custom_attributes.update",
                  );
              },
            );
          });
        });
      });
    });

    // Carriers
    adminRouter.group("/carriers", (adminRouter) => {
      adminRouter.get("").setId("@admin.carriers.search");
      adminRouter.get("/create").setId("@admin.carriers.create");

      adminRouter.group("/<carrierId>", (adminRouter) => {
        adminRouter.setConstraint("carrierId", /\d+/);
        adminRouter.get("/show").setId("@admin.carriers.view");
        adminRouter.get("/edit").setId("@admin.carriers.update");
      });
    });

    // Api
    adminRouter.group("/api", (adminRouter) => {
      // Pampa
      adminRouter
        .get("/resize<slug>")
        .setId("pampa")
        .setConstraint("slug", /.*/);
    });
  });

  return adminRouter;
}

export const adminRouter = buildRouter(adminBaseUri);
export const adminRouterWithBasePath = buildRouter(adminBaseUriWithBasePath);
