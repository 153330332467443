/* User types */

export const USERS_USER_TYPE_VALUE_ADMIN = "admin";
export const USERS_USER_TYPE_VALUE_CUSTOMER = "customer";

export const USERS_USER_TYPES = {
  [USERS_USER_TYPE_VALUE_ADMIN]: {
    id: USERS_USER_TYPE_VALUE_ADMIN,
  },
  [USERS_USER_TYPE_VALUE_CUSTOMER]: {
    id: USERS_USER_TYPE_VALUE_CUSTOMER,
  },
};
