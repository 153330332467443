// deps
import {
  Box,
  Button,
  Code,
  Collapse,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { errorBoundaryGetHrefContact } from "@raiden/library/helpers/errorBoundary/getHrefContact";
import { Component } from "react";
import { MdEmail, MdError, MdExpandLess, MdExpandMore } from "react-icons/md";
import { FormattedMessage } from "react-intl";

export class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      error: /** @type {any} */ (null),
      isDetailVisible: false,
    };
  }

  /**
   *
   * @returns {import("react").ReactNode}
   */
  render() {
    const { children } = this.props;
    const { error, isDetailVisible } = this.state;

    if (error) {
      return (
        <Box p="1rem">
          <Stack
            spacing="1rem"
            borderWidth="2px"
            borderRadius="0.5rem"
            p="1rem">
            <HStack spacing="1rem">
              <Icon as={MdError} color="red.600" boxSize="1.5rem" />

              <Text fontSize="1.5rem">
                <FormattedMessage defaultMessage="Quelque chose ne s'est pas passé comme prévu." />
              </Text>
            </HStack>

            <Text fontSize="1rem">
              <FormattedMessage defaultMessage="Une erreur s'est produite. Si le problème persiste, merci de prendre le temps de nous en informer en envoyant un email." />
            </Text>

            <Box>
              <Button
                as="a"
                colorScheme="blue"
                leftIcon={<Icon as={MdEmail} />}
                href={errorBoundaryGetHrefContact({ error })}>
                <FormattedMessage defaultMessage="Nous contacter" />
              </Button>
            </Box>

            <Box>
              <Button
                onClick={() => {
                  this.setState({
                    ...this.state,
                    isDetailVisible: !isDetailVisible,
                  });
                }}
                rightIcon={
                  <Icon as={isDetailVisible ? MdExpandLess : MdExpandMore} />
                }
                size="xs"
                borderBottomRadius={isDetailVisible ? "none" : undefined}>
                <FormattedMessage defaultMessage="Détails techniques" />
              </Button>

              <Collapse in={isDetailVisible} unmountOnExit={true}>
                <Stack>
                  <Code borderTopLeftRadius="none" p=".5rem">
                    {error?.toString()}
                  </Code>
                </Stack>
              </Collapse>
            </Box>
          </Stack>
        </Box>
      );
    }

    return children;
  }
}
