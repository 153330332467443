// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

const apiRouter = new Router();

apiRouter.group(
  // @ts-ignore
  new Uri(
    process.env.NEXT_PUBLIC_API_SCHEME,
    null,
    process.env.NEXT_PUBLIC_API_HOST,
    null,
    process.env.NEXT_PUBLIC_API_BASE_PATH,
  ),
  function (apiRouter) {
    apiRouter.group("/api", (apiRouter) => {
      // Configuration
      apiRouter.get("/configuration").setId("@api.configuration");

      // Environments
      apiRouter.group("/environments", (apiRouter) => {
        apiRouter.get("").setId("@api.environments.viewAny");

        apiRouter.post("").setId("@api.environments.create");

        apiRouter.group("/<environmentId>", (apiRouter) => {
          apiRouter.setConstraint("environmentId", /\d+/);

          apiRouter.get("/quotas").setId("@api.environments.quotas");

          apiRouter.get("").setId("@api.environments.view");

          apiRouter.post("").setId("@api.environments.update");

          apiRouter.delete("").setId("@api.environments.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@api.environments.restore");
          });
        });
      });

      // Users
      apiRouter.group("/users", (apiRouter) => {
        // Users/Groups
        apiRouter.group("/groups", (apiRouter) => {
          apiRouter.get("").setId("@api.usersGroups.viewAny");

          apiRouter.post("").setId("@api.usersGroups.create");

          apiRouter.group("/<usersGroupId>", (apiRouter) => {
            apiRouter.setConstraint("usersGroupId", /\d+/);

            apiRouter.get("").setId("@api.usersGroups.view");

            apiRouter.post("").setId("@api.usersGroups.update");

            apiRouter.delete("").setId("@api.usersGroups.delete");
          });
        });

        // Users/Admins
        apiRouter.group("/admins", (apiRouter) => {
          apiRouter.get("").setId("@api.usersAdmins.viewAny");

          apiRouter.post("").setId("@api.usersAdmins.create");

          apiRouter.group("/<usersAdminId>", (apiRouter) => {
            apiRouter.setConstraint("usersAdminId", /\d+/);

            apiRouter.get("").setId("@api.usersAdmins.view");

            apiRouter.post("").setId("@api.usersAdmins.update");

            apiRouter.delete("").setId("@api.usersAdmins.delete");
          });
        });

        // Users/PasswordResets
        apiRouter.group("/password-resets", (apiRouter) => {
          apiRouter.post("").setId("@api.usersPasswordResets.create");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter
              .post("/finish")
              .setId("@api.usersPasswordResets.actionFinish");
          });
        });

        // Users/Customers
        apiRouter.group("/customers", (apiRouter) => {
          apiRouter.get("").setId("@api.customers.viewAny");
          apiRouter.post("").setId("@api.customers.create");
          apiRouter.group("/<customerId>", (apiRouter) => {
            apiRouter.setConstraint("customerId", /\d+/);
            apiRouter.get("").setId("@api.customers.view");
            apiRouter.post("").setId("@api.customers.update");
            apiRouter.delete("").setId("@api.customers.delete");
            apiRouter.group("/action", (apiRouter) => {
              apiRouter.post("/restore").setId("@api.customers.restore");
            });
          });
        });

        // Users/Addresses
        apiRouter.group("/addresses", (apiRouter) => {
          apiRouter.get("").setId("@api.addresses.viewAny");
          apiRouter.post("").setId("@api.addresses.create");
          apiRouter.group("/<addressId>", (apiRouter) => {
            apiRouter.setConstraint("addressId", /\d+/);
            apiRouter.post("").setId("@api.addresses.update");
            apiRouter.delete("").setId("@api.addresses.delete");
            apiRouter.group("/action", (apiRouter) => {
              apiRouter.post("/restore").setId("@api.addresses.restore");
            });
          });
        });

        // Users/2FA
        apiRouter.group("/two-factor-authentication", (apiRouter) => {
          apiRouter.get("").setId("@api.2fa.view");
          apiRouter.delete("").setId("@api.2fa.delete");
          apiRouter.post("").setId("@api.2fa.enable");
          apiRouter.post("/enrollment").setId("@api.2fa.enrollment");
          apiRouter.post("/challenge").setId("@api.2fa.challenge");
        });
      });

      // Customers/Contacts
      apiRouter.group("/customers/contacts", (apiRouter) => {
        apiRouter.get("").setId("@api.customers.contacts.viewAny");
        apiRouter.post("").setId("@api.customers.contacts.create");

        apiRouter.group("/<customerContactId>", (apiRouter) => {
          apiRouter.setConstraint("customerContactId", /\d+/);
          apiRouter.get("").setId("@api.customers.contacts.view");
          apiRouter.post("").setId("@api.customers.contacts.update");
          apiRouter.delete("").setId("@api.customers.contacts.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@api.customers.contacts.restore");
          });
        });

        // Customers/Contacts/Categories
        apiRouter.group("/categories", (apiRouter) => {
          apiRouter.get("").setId("@api.customers.contacts.categories.viewAny");
          apiRouter.post("").setId("@api.customers.contacts.categories.create");

          apiRouter.group("/<customerContactCategoryId>", (apiRouter) => {
            apiRouter.setConstraint("customerContactCategoryId", /\d+/);
            apiRouter.get("").setId("@api.customers.contacts.categories.view");
            apiRouter
              .post("")
              .setId("@api.customers.contacts.categories.update");
            apiRouter
              .delete("")
              .setId("@api.customers.contacts.categories.delete");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter
                .post("/restore")
                .setId("@api.customers.contacts.categories.restore");
            });
          });

          // Customers/Contacts/Categories/CustomAttributes
          apiRouter.group("/custom-attributes", (apiRouter) => {
            apiRouter
              .get("")
              .setId(
                "@api.customers.contacts.categories.custom_attributes.viewAny",
              );
            apiRouter
              .post("")
              .setId(
                "@api.customers.contacts.categories.custom_attributes.create",
              );

            apiRouter.group(
              "/<customerContactCategoryCustomAttributeId>",
              (apiRouter) => {
                apiRouter.setConstraint(
                  "customerContactCategoryCustomAttributeId",
                  /\d+/,
                );
                apiRouter
                  .get("")
                  .setId(
                    "@api.customers.contacts.categories.custom_attributes.view",
                  );
                apiRouter
                  .post("")
                  .setId(
                    "@api.customers.contacts.categories.custom_attributes.update",
                  );
                apiRouter
                  .delete("")
                  .setId(
                    "@api.customers.contacts.categories.custom_attributes.delete",
                  );

                apiRouter.group("/action", (apiRouter) => {
                  apiRouter
                    .post("/restore")
                    .setId(
                      "@api.customers.contacts.categories.custom_attributes.restore",
                    );
                });
              },
            );
          });
        });
      });

      // Posts
      apiRouter.group("/posts", (apiRouter) => {
        apiRouter.get("").setId("@api.posts.viewAny");

        apiRouter.post("").setId("@api.posts.create");

        apiRouter.group("/<postId>", (apiRouter) => {
          apiRouter.setConstraint("postId", /\d+/);

          apiRouter.get("").setId("@api.posts.view");

          apiRouter.post("").setId("@api.posts.update");

          apiRouter.delete("").setId("@api.posts.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@api.posts.restore");
          });
        });

        // Posts/Categories
        apiRouter.group("/categories", (apiRouter) => {
          apiRouter.get("").setId("@api.postsCategories.viewAny");

          apiRouter.get("/tree").setId("@api.postsCategories.treeView");

          apiRouter.post("/tree").setId("@api.postsCategories.treeUpdate");

          apiRouter.post("").setId("@api.postsCategories.create");

          apiRouter.group("/<postsCategoryId>", (apiRouter) => {
            apiRouter.setConstraint("postsCategoryId", /\d+/);

            apiRouter.get("").setId("@api.postsCategories.view");

            apiRouter.post("").setId("@api.postsCategories.update");

            apiRouter.post("").setId("@api.postsCategories.delete");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter.group("/restore", (apiRouter) => {
                apiRouter.post("").setId("@api.postsCategories.restore");
              });
            });
          });
        });

        apiRouter.group("/revisions", (apiRouter) => {
          apiRouter.group("/<postsRevisionId>", (apiRouter) => {
            apiRouter.setConstraint("postsRevisionId", /\d+/);

            apiRouter.post("").setId("@api.postsRevisions.update");

            apiRouter.delete("").setId("@api.postsRevisions.delete");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter.post("/review").setId("@api.postsRevisions.review");

              apiRouter.post("/restore").setId("@api.postsRevisions.restore");
            });
          });
        });
      });

      // Covers
      apiRouter.group("/covers", (apiRouter) => {
        apiRouter.get("").setId("@api.covers.viewAny");

        apiRouter.post("").setId("@api.covers.create");

        apiRouter.group("/<coverId>", (apiRouter) => {
          apiRouter.setConstraint("coverId", /\d+/);

          apiRouter.post("").setId("@api.covers.update");

          apiRouter.delete("").setId("@api.covers.delete");
        });
      });

      // Files
      apiRouter.group("/files", (apiRouter) => {
        apiRouter.get("").setId("@api.files.viewAny");

        apiRouter.post("").setId("@api.files.create");

        apiRouter.group("/<fileId>", (apiRouter) => {
          apiRouter.setConstraint("fileId", /\d+/);

          apiRouter.delete("").setId("@api.files.delete");

          apiRouter.get("").setId("@api.files.view");

          apiRouter.post("").setId("@api.files.update");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@api.files.restore");
          });
        });
      });

      // Countries
      apiRouter.group("/countries", (apiRouter) => {
        apiRouter.get("").setId("@api.countries.viewAny");
        apiRouter.post("").setId("@api.countries.create");

        apiRouter.group("/<countryId>", (apiRouter) => {
          apiRouter.setConstraint("countryId", /\d+/);
          apiRouter.get("").setId("@api.countries.view");
          apiRouter.post("").setId("@api.countries.update");
          apiRouter.post("").setId("@api.countries.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@api.countries.restore");
          });
        });
      });

      // Menus
      apiRouter.group("/menus", (apiRouter) => {
        apiRouter.get("").setId("@api.menus.viewAny");
        apiRouter.post("").setId("@api.menus.create");

        apiRouter.group("/<menuId>", (apiRouter) => {
          apiRouter.setConstraint("menuId", /\d+/);
          apiRouter.get("").setId("@api.menus.view");
          apiRouter.post("").setId("@api.menus.update");
          apiRouter.post("").setId("@api.menus.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@api.menus.restore");
          });
        });
      });

      // invoices
      apiRouter.group("/invoices", (apiRouter) => {
        apiRouter.get("").setId("@api.invoices.viewAny");

        // invoices/{invoiceId}
        apiRouter.group("/<invoiceId>", (apiRouter) => {
          apiRouter.setConstraint("invoiceId", /\d+/);

          // invoices/{invoiceId}/action
          apiRouter.group("/action", (apiRouter) => {
            apiRouter.get("/download").setId("@api.invoices.download");
          });
        });

        // invoices/{invoiceId}/action
        apiRouter.group("/action", (apiRouter) => {
          apiRouter.get("/export").setId("@api.invoices.export");
        });
      });

      // accounts
      apiRouter.group("/accounts", (apiRouter) => {
        // accounts/records
        apiRouter.group("/records", (apiRouter) => {
          // accounts/records/exports
          apiRouter.group("/exports", (apiRouter) => {
            apiRouter.get("").setId("@api.accountsRecordsExports.viewAny");
            // accounts/records/exports/{exportId}
            apiRouter.group("/<exportId>", (apiRouter) => {
              apiRouter.setConstraint("exportId", /\d+/);
              // accounts/records/exports/{exportId}/action
              apiRouter.group("/action", (apiRouter) => {
                apiRouter
                  .get("/download")
                  .setId("@api.accountsRecordsExports.download");
              });
            });
          });
        });
      });

      // Carriers
      apiRouter.group("/carriers", (apiRouter) => {
        apiRouter.get("").setId("@api.carriers.viewAny");
        apiRouter.post("").setId("@api.carriers.create");

        apiRouter.group("/<carrierId>", (apiRouter) => {
          apiRouter.setConstraint("carrierId", /\d+/);
          apiRouter.get("").setId("@api.carriers.view");
          apiRouter.post("").setId("@api.carriers.update");
          apiRouter.post("").setId("@api.carriers.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@api.carriers.restore");
            apiRouter.post("/associate").setId("@api.carriers.associate");
            apiRouter.post("/dissociate").setId("@api.carriers.dissociate");
          });
        });
      });

      // Authorizations
      apiRouter.group("/authorizations", (apiRouter) => {
        apiRouter.get("").setId("@api.authorizations.viewAny");
      });
    });

    apiRouter.group("/auth", (apiRouter) => {
      apiRouter.post("/login").setId("@api.auth.login");

      apiRouter.post("/me").setId("@api.auth.me");

      apiRouter.post("/logout").setId("@api.auth.logout");
    });

    apiRouter.group("/sanctum", (apiRouter) => {
      apiRouter.group("/csrf-cookie", (apiRouter) => {
        apiRouter.get("").setId("@api.sanctum.csrfCookie");
      });
    });

    apiRouter.group("/laravel", (apiRouter) => {
      apiRouter.get("/telescope").setId("@api.laravel.telescope");

      apiRouter.get("/horizon").setId("@api.laravel.horizon");
    });
  },
);

export default apiRouter;
